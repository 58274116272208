import { TsInput } from '@tse-core/ts-components';
import { Input, Popover, Tooltip } from 'antd';
import React, { FC } from 'react';
import reactComponentDebounce from 'react-component-debounce';
import { IVpinputProps } from '.';
import './vpinput.less';

type IReducedIVpinputProps = Omit<IVpinputProps, 'value' | 'defaultValue'>;

/**
 * Used for remove value and default value from props
 */
const reduceProps = (props: IVpinputProps): IReducedIVpinputProps => {
  var clone = Object.assign({}, props);
  delete clone.value;
  delete clone.defaultValue;
  return clone as IReducedIVpinputProps;
};

export const Vpinput: FC<IVpinputProps> = props => {
  const {
    allowClear,
    className,
    optionalText,
    defaultValue,
    disabled,
    invalidMessage,
    isNotValid,
    placeHolder,
    value,
    label,
    style,
    onChange,
    type,
    required,
    infoMessage,
    readOnly,
    infoPopover
  } = props;
  const cssClassPrefix = 'vpinput';

  //It's important to assure default value work
  const valProp = defaultValue ? {} : { value: undefined };
  if (value) {
    valProp['value'] = value;
  }

  const dateProps = { ...reduceProps(props), ...valProp, defaultValue: defaultValue };

  return (
    <div className={cssClassPrefix} style={style}>
      <div className={`${cssClassPrefix}-head`}>
        {label && (
          <span className={`${cssClassPrefix}-label`}>
            {label}:{required && <span className="required">*</span>}
          </span>
        )}
        {infoMessage && (
          <Tooltip placement="top" title={infoMessage}>
            <span className={`${cssClassPrefix}-info`}>
              <i className="fas fa-exclamation-circle"></i>
            </span>
          </Tooltip>
        )}
        {infoPopover && (
          <Popover content={infoPopover}>
            <span className={`${cssClassPrefix}-info`}>
              <i className="fas fa-exclamation-circle"></i>
            </span>
          </Popover>
        )}
      </div>
      {optionalText && <span className={`${cssClassPrefix}-optional-text`}>{optionalText}</span>}
      {(type === undefined || (type && type === 'text')) && (
        <>
          <TsInput
            {...reduceProps(props)}
            {...valProp}
            defaultValue={defaultValue}
            className={`${className ? className : ''} ${allowClear ? 'allow-clear' : ''} ${isNotValid ? `${cssClassPrefix}-error-border` : ''}`}
            disabled={disabled}
            readOnly={readOnly}
            placeholder={placeHolder}
            onChange={onChange}
            style={{ width: '100%' }}
          />
          {/* <Input
            {...reduceProps(props)}
            {...valProp}
            className={`${className ? className : ''} ${allowClear ? 'allow-clear' : ''} ${isNotValid ? `${cssClassPrefix}-error-border` : ''}`}
            defaultValue={defaultValue}
            disabled={disabled}
            readOnly={readOnly}
            placeholder={placeHolder}
            onChange={onChange}
          /> */}
        </>
      )}
      {type && type === 'password' && (
        <Input.Password
          {...reduceProps(props)}
          visibilityToggle={false}
          className={`${className ? className : ''} ${allowClear ? 'allow-clear' : ''} ${isNotValid ? `${cssClassPrefix}-error-border` : ''}`}
          defaultValue={defaultValue}
          disabled={disabled}
          readOnly={readOnly}
          {...valProp}
          placeholder={placeHolder}
          onChange={onChange}
        />
      )}
      {type && type === 'number' && (
        <Input
          {...reduceProps(props)}
          className={`${className ? className : ''} ${allowClear ? 'allow-clear' : ''} ${isNotValid ? `${cssClassPrefix}-error-border` : ''}`}
          defaultValue={defaultValue}
          disabled={disabled}
          readOnly={readOnly}
          {...valProp}
          placeholder={placeHolder}
          onChange={onChange}
        />
      )}
      {type && type === 'date' && (
        <>
          <Input
            {...dateProps}
            //{...reduceProps(props)}
            className={`${className ? className : ''} ${allowClear ? 'allow-clear' : ''} ${isNotValid ? `${cssClassPrefix}-error-border` : ''}`}
            //defaultValue={defaultValue}
            disabled={disabled}
            readOnly={readOnly}
            // {...valProp}
            placeholder={placeHolder}
            onChange={onChange}
          />
        </>
      )}
      {isNotValid && <div className={`${cssClassPrefix}-error`}>{invalidMessage}</div>}
    </div>
  );
};

export const VpInputDebounced = reactComponentDebounce({
  valuePropName: 'value',
  triggerMs: 300
})(Vpinput);
