import React, { FC } from "react";
import "./vpgdpr.less";

const Vpgdpr: FC<{gdprLink: string, cookieLink: string}> = (props) => {
const {gdprLink, cookieLink} = props;
  const cssClassPrefix = 'vpgdpr';

  return (
    <div className={cssClassPrefix}>
        <span>
          Il tuo accesso al software &egrave; garantito dal TeamSystem ID. l’identificativo universale per accedere alle <br /> tue piattaforme gestionali, nel
          rispetto dei requisiti di sicurezza previsti dal GDPR. &nbsp;<a href={gdprLink}>Leggi le istruzioni sulla GDPR</a>
        </span>
        <span>
          TeamSystem utilizza cookie tecnici. Effettuando l'accesso acconsenti all'uso dei cookie. &nbsp;<a href={cookieLink}>Leggi l'informativa</a>
        </span>
    </div>
  );
};

export default Vpgdpr;