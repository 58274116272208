import { TsButtonSize, TsButtonType } from '@tse-core/ts-components/lib/components/TsButton';
import { Button } from 'antd';
import { ButtonSize, ButtonType } from 'antd/lib/button';
import React, { FC } from 'react';
import { IVpbuttonProps } from '.';
import './vpbutton.less';

export const Vpbutton: FC<IVpbuttonProps> = props => {
  const { children, type, size, faIcon, disabled, onClick, htmlType, alert } = props;

  const getButtonType = (): ButtonType => {
    switch (type) {
      case 'primary':
        return 'primary';
      case 'secondary':
        return 'default';
      case 'tertiary':
      case 'quaternary':
        return 'link';
      default:
        return 'primary';
    }
  };

  const getTSButtonType = (alert: boolean): TsButtonType => {
    switch (type) {
      case 'primary':
        return !alert ? TsButtonType.PRIMARY : TsButtonType.PRIMARY_DANGER;
      case 'secondary':
        return !alert ? TsButtonType.SECONDARY : TsButtonType.SECONDARY_DANGER;
      case 'tertiary':
      case 'quaternary':
        return !alert ? TsButtonType.TERTIARY : TsButtonType.TERTIARY_DANGER;
      default:
        return !alert ? TsButtonType.PRIMARY : TsButtonType.PRIMARY_DANGER;
    }
  };
  const getButtonSize = (): ButtonSize => {
    switch (size) {
      case 'small':
        return 'small';
      case 'medium':
        return 'default';
      case 'large':
        return 'large';
      default:
        return 'default';
    }
  };
  const getTSButtonSize = (): TsButtonSize => {
    switch (size) {
      case 'small':
        return TsButtonSize.SMALL;
      case 'medium':
        return TsButtonSize.DEFAULT;
      case 'large':
        return TsButtonSize.LARGE;
      default:
        return TsButtonSize.DEFAULT;
    }
  };

  return (
    //htmlType ?
      <Button className={`vpbutton ${alert ? 'vpbutton-alert' : ''} ${type === 'quaternary' ? 'quaternary' : ''}`} type={getButtonType()} htmlType={htmlType} size={getButtonSize()} disabled={disabled} onClick={evt => onClick && onClick(evt)}>
        {faIcon && <i className={faIcon}></i>}
        {children}
      </Button>
      // : <TsButton type={getTSButtonType(alert)} size={getTSButtonSize()} disabled={disabled} onClick={evt => onClick && onClick(evt)}>
      //   {faIcon && <i className={faIcon}></i>}
      //   {children}
      // </TsButton>
  );
};
