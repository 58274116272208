import { Icon, Spin } from 'antd';
import React, { FC } from 'react';
import './vploading.less';

export const Vploading: FC<IVploading> = props => {
  const { neutralmode } = props;
  return (
    <div className={`vploading ${neutralmode ? 'neutral' : ''}`} key="LoadingStyle" {...props}>
      <div className="overlay-content">
        {neutralmode ? <Spin size="large"/> : <Icon type="loading" spin />}
      </div>
    </div>
  );
};

export interface IVploading {
  neutralmode?: boolean;
}
